import { DateString } from '@app/types/utilityTypes';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useToast from './useToast';
import useClipboard from './useClipboard';
import { ToastTypes } from '@app/common/components/Toast';
import { getErrorMessageForToast } from '@app/utils/errorUtils';

/**
 * Fetches the signing link for a UaD or BaD client
 */
const useSigningLinkQuery = (clientId?: string, documentId?: string, sentForSigningDate?: DateString) =>
    useQuery(
        ['signingLink', clientId, documentId, sentForSigningDate],
        async () => {
            const response = await axios.get(`/api/document/${documentId}/signing-link/${clientId}`);
            return response?.data?.link;
        },
        {
            enabled: false, // Disable the query because we don't want it to run automatically
            retry: false
        }
    );

/**
 * Copies the signing link for a document (BaD/UaD) client to the clipboard. If the link is not available, an error toast is shown.
 */
export const useCopySigningLink = (clientId?: string, documentId?: string, sentForSigningDate?: DateString) => {
    const { addNewToast } = useToast();
    const { copyToClipboard } = useClipboard();

    const { isLoading, fetchStatus, refetch } = useSigningLinkQuery(clientId, documentId, sentForSigningDate);

    const copySigningLink = async () => {
        const { data } = await refetch();
        if (data) {
            copyToClipboard(data, 'Link copied');
        } else {
            addNewToast(getErrorMessageForToast('Unable to copy signing link.'), ToastTypes.ERROR, true);
        }
    };

    return { copySigningLink, isSigningLinkLoading: isLoading && fetchStatus !== 'idle' };
};
