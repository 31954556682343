import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HARNESS_IO_ANON_USER } from '../../constants/constants';
import { omit } from 'lodash';
import { extractUserAndAgencyInfo } from '../../utils/vendorUtils';
import { getUserInfo as userInfoSelector } from '../../selectors/user';
import { getAgency } from '../../selectors/settings/account';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { HARNESS_TARGET_DATA } from '@app/constants/constants';

export const getHarnessTargetData = (userInfo, agency) => {
    const user = extractUserAndAgencyInfo(userInfo, agency);
    // set harness.io target, omit phone as that should never be needed
    const customUserAttributes = omit(user, ['key', 'name', 'email', 'country', 'phone']);
    return { name: user.name, identifier: user.key, attributes: customUserAttributes };
};

export function HarnessProvider({ children }) {
    const targetUserId = React.useRef();
    const targetAgencyId = React.useRef();

    const userInfo = useSelector(userInfoSelector);
    const agency = useSelector(getAgency);

    // Iniitialise harnessTargetData from sessionStorage
    const cachedHarnessTargetData = window.sessionStorage.getItem(HARNESS_TARGET_DATA);
    const harnessTargetData = cachedHarnessTargetData ? JSON.parse(cachedHarnessTargetData) : undefined;
    const [harnessIOTarget, setHarnessIOTarget] = useState(harnessTargetData || { identifier: HARNESS_IO_ANON_USER });

    useEffect(() => {
        // Only update harness target if user or agency has changed
        if (
            userInfo?.id &&
            agency?.id &&
            (userInfo.id !== targetUserId.current || agency.id !== targetAgencyId.current)
        ) {
            targetUserId.current = userInfo.id;
            targetAgencyId.current = agency.id;
            setHarnessIOTarget(getHarnessTargetData(userInfo, agency));
        }
    }, [userInfo, agency, harnessIOTarget]);

    return (
        <FFContextProvider apiKey={HARNESS_IO_API_KEY} target={harnessIOTarget} async>
            {children}
        </FFContextProvider>
    );
}
