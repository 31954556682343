import * as React from 'react';
import { connect } from 'react-redux';

import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import { closeTerminateLeaseModal, updateTerminateLeaseData } from '../../actions/terminateLease';
import { terminateLease, updateTerminateNoticeSenderEmailAddresses } from '../../actions/lease';

import '../../sass/terminateLeaseModal.scss';
import { Form, FormSpy } from 'react-final-form';
import debounce from 'lodash/debounce';
import { FormTextRegular, FormTextMultiline } from '../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../components/form/DatePickerInAgencyTimeZone';
import { SelectField } from '../../components/form/FormSelect';

import {
    leaseTerminationReasonOptions,
    HIDE_MESSAGE_TIME,
    DEBOUNCE_TIME,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    DOCUMENT_TERMINATION_NOTICE
} from '../../config';
import { getAgencyTimezoneDate, getDeliveryDate, isWeekday } from '../../utils/dateUtils';
import DeliveryTypeComponent from '../../containers/dashboard/shared/DeliveryTypeComponent';
import { isLeaseLocationQLD } from '../../utils/agreementUtils';
import QldNoticeReason from '../../containers/dashboard/documents/termination/QldNoticeReason';
import { isEmpty, values as lodashValues } from 'lodash';
import Icon, { Icons, IconSize } from '@app/common/components/Icon';
import ReactTooltip from 'react-tooltip';
import { getLabel } from '@app/utils/labelUtils';

class TerminateLeaseModal extends React.Component {
    constructor(props) {
        super(props);
        this.changeForm = debounce(this.changeForm.bind(this), DEBOUNCE_TIME);
        this.state = {
            addEmailFieldError: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isSendingTerminateLeaseSuccess !== prevProps.isSendingTerminateLeaseSuccess) {
            setTimeout(() => {
                this.props.closeTerminateLeaseModal();
            }, HIDE_MESSAGE_TIME);
        }
    }

    closeModal = () => {
        this.props.closeModal();
    };

    handleSubmit = () => {
        const data = { ...this.props.formData };
        this.setState({
            addEmailFieldError: ''
        });
        if (typeof data.dateOfNotice === 'undefined') {
            data.dateOfNotice = null;
        }
        if (typeof data.vacantPossessionDate === 'undefined') {
            data.vacantPossessionDate = null;
        }
        if (typeof data.saleDate === 'undefined') {
            data.saleDate = null;
        }
        if (typeof data.detailsOfBreach === 'undefined') {
            data.detailsOfBreach = null;
        }
        if (typeof data.detailsOfGrounds === 'undefined') {
            data.detailsOfGrounds = null;
        }
        // Don't have reason for termination for QLD
        if (isLeaseLocationQLD(this.props.agreementInfo.location)) {
            data.reasonForTermination = ' ';
        }
        this.props.updateTerminateLeaseData(data);
        if (data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [this.props.loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        if (this.validateEmailList(data)) {
            this.props.terminateLease(data.leaseId, data);
        }
    };

    renderRightButton = () => {
        return (
            <Button
                primary
                className="btn-terminate"
                height={40}
                type="submit"
                disabled={this.props.isSendingTerminateLease}
            >
                {this.props.isSendingTerminateLease && <span className="savestatus-spinner-await-completion" />}
                Terminate
            </Button>
        );
    };

    renderLeftButton = () => {
        return (
            <Button
                primary
                className="btn-close"
                height={40}
                onClick={this.closeModal}
                disabled={this.props.isSendingTerminateLease}
            >
                Cancel
            </Button>
        );
    };

    changeForm = values => {
        this.props.updateTerminateLeaseData(values);
    };

    validateEmailList = values => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (values.emailAddresses.length === 0) {
                this.setState({
                    addEmailFieldError: 'Please enter at least one email address'
                });
                return false;
            }
        }
        this.setState({
            addEmailFieldError: ''
        });
        return true;
    };

    // Required as calling getDeliveryDate directly in the DatePicker selected field results in the function not working where dateOfNotice is null however calling it here does work
    setSelectedDate = values => {
        values.dateOfNotice = getDeliveryDate(values.dateOfNotice, this.props.loggedInUser, values.deliveryType);
        return values.dateOfNotice;
    };

    render() {
        let { isOpen, agreementInfo } = this.props;
        return (
            <ModalDialog
                title="Notice of termination"
                isOpen={isOpen}
                closeModal={this.closeModal}
                shouldCloseOnOverlayClick={false}
                className="terminate-modal-dialog"
            >
                <Form
                    initialValues={this.props.formData}
                    onSubmit={() => {
                        this.handleSubmit();
                    }}
                    validate={({
                        vacantPossessionDate,
                        deliveryType,
                        reasonForTermination,
                        detailsOfBreach,
                        saleDate,
                        detailsOfGrounds,
                        deliveryTypeDetails,
                        dateOfNotice,
                        emailTo,
                        qldNoticeReason
                    }) => {
                        let qldNoticeReasonError;
                        if (isLeaseLocationQLD(agreementInfo.location)) {
                            qldNoticeReasonError = lodashValues(qldNoticeReason).some(i => i === true)
                                ? undefined
                                : 'At lease one or more reasons for termination is required';
                        }
                        const clientSideErrors = {
                            vacantPossessionDate:
                                vacantPossessionDate === null
                                    ? 'Delivery date of vacant possession is required'
                                    : undefined,
                            deliveryType: !deliveryType ? 'Delivery type is required' : undefined,
                            deliveryTypeDetails:
                                deliveryType && deliveryType === 'other' && !deliveryTypeDetails
                                    ? 'Details of the delivery type is required'
                                    : undefined,
                            detailsOfBreach:
                                reasonForTermination && reasonForTermination === 'breach' && detailsOfBreach === null
                                    ? 'Details of the breach is required'
                                    : undefined,
                            saleDate:
                                reasonForTermination && reasonForTermination === 'sold' && saleDate === null
                                    ? 'Sale date is required'
                                    : undefined,
                            detailsOfGrounds:
                                reasonForTermination &&
                                reasonForTermination === 'with_grounds' &&
                                detailsOfGrounds === null
                                    ? 'Details of the grounds is required'
                                    : undefined,
                            dateOfNotice: dateOfNotice === null ? 'Date of notice is required' : undefined,
                            emailTo:
                                deliveryType && deliveryType === DELIVERY_TYPE_EMAIL && !emailTo
                                    ? 'Please select the receiver of the termination email'
                                    : undefined,
                            qldNoticeReason: {
                                error: qldNoticeReasonError
                            }
                        };
                        return this.props.validationErrors && this.props.validationErrors.tenant
                            ? this.props.validationErrors.tenant
                            : clientSideErrors;
                    }}
                >
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <FormSpy
                                    subscription={{ active: true, values: true }}
                                    onChange={state => this.changeForm(state.values)}
                                />
                                <div className="terminate-lease">
                                    <div className="">
                                        <FormTextRegular name={'tenants'} label="Tenants" disabled />
                                        <FormTextRegular name={'address'} label="Address" disabled />
                                        <FormTextRegular name={'agentName'} label="Agent" disabled />
                                        {!isLeaseLocationQLD(agreementInfo.location) && (
                                            <SelectField
                                                label="Reason for termination"
                                                name="reasonForTermination"
                                                options={leaseTerminationReasonOptions[agreementInfo.location]}
                                                disabled={values.disabled}
                                            />
                                        )}
                                        {isLeaseLocationQLD(agreementInfo.location) && <QldNoticeReason form={form} />}
                                        {values.reasonForTermination && values.reasonForTermination === 'breach' && (
                                            <FormTextMultiline name="detailsOfBreach" label="Details of the breach" />
                                        )}
                                        {values.reasonForTermination && values.reasonForTermination === 'sold' && (
                                            <div className="calendar">
                                                <DatePickerInAgencyTimeZone
                                                    label="Sale Date"
                                                    name="saleDate"
                                                    selected={values.saleDate}
                                                />
                                            </div>
                                        )}
                                        {values.reasonForTermination &&
                                            values.reasonForTermination === 'with_grounds' && (
                                                <FormTextMultiline
                                                    name="detailsOfGrounds"
                                                    label="Details of the grounds"
                                                />
                                            )}
                                        <div className="calendar">
                                            <DatePickerInAgencyTimeZone
                                                label="The tenant will deliver vacant possession of the premises on:"
                                                name="vacantPossessionDate"
                                                selected={values.vacantPossessionDate}
                                                minValue={getAgencyTimezoneDate(new Date(), this.props.loggedInUser)}
                                                filterDate={
                                                    isLeaseLocationQLD(agreementInfo.location) ? isWeekday : undefined
                                                }
                                                toolTipIcon={
                                                    isLeaseLocationQLD(agreementInfo.location) && (
                                                        <Icon
                                                            icon={Icons.INFO}
                                                            size={IconSize.SMALL}
                                                            data-for="terminationNoticeNoWeekendTooltip"
                                                            className="icon terminate-lease__info-icon"
                                                            data-tip={true}
                                                        />
                                                    )
                                                }
                                            />
                                            {isLeaseLocationQLD(agreementInfo.location) && (
                                                <ReactTooltip
                                                    place="right"
                                                    effect="solid"
                                                    id="terminationNoticeNoWeekendTooltip"
                                                    globalEventOff="wheel"
                                                >
                                                    <div>
                                                        <strong>Note</strong>:{' '}
                                                        {getLabel(
                                                            'terminationNoticeDateNotice',
                                                            agreementInfo.location,
                                                            DOCUMENT_TERMINATION_NOTICE
                                                        )}
                                                    </div>
                                                </ReactTooltip>
                                            )}
                                        </div>
                                        <DeliveryTypeComponent
                                            values={values}
                                            form={form}
                                            addEmailFieldError={this.state.addEmailFieldError}
                                        />
                                        <div className="calendar">
                                            <DatePickerInAgencyTimeZone
                                                label="Date of the notice:"
                                                name="dateOfNotice"
                                                selected={this.setSelectedDate(values)}
                                                minValue={getAgencyTimezoneDate(new Date(), this.props.loggedInUser)}
                                                disabled={values.deliveryType === 'email'}
                                            />
                                        </div>
                                        <div className="section space-between">
                                            <div className="footer footer-draft">
                                                {this.props.isSendingTerminateLeaseSuccess && (
                                                    <span className="savestatus-saved">
                                                        <span className="icon savestatus-icon" />
                                                        Termination notice generation started successfully.
                                                    </span>
                                                )}
                                                {this.props.isSendingTerminateLeaseFail && (
                                                    <span className="savestatus-failed">
                                                        <span className="icon savestatus-icon" />
                                                        Sending the termination notice failed.
                                                    </span>
                                                )}
                                                <div className="button-row">
                                                    <div className="button btn-await-completion">
                                                        {this.renderLeftButton()}
                                                    </div>
                                                    <div className="button btn-await-completion">
                                                        {this.renderRightButton()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.terminateLease.isTerminateLeaseModalOpen,
        formData: state.terminateLease.data,
        isSendingTerminateLease: state.lease.common.isSendingTerminateLease,
        isSendingTerminateLeaseSuccess: state.lease.common.isSendingTerminateLeaseSuccess,
        isSendingTerminateLeaseFail: state.lease.common.isSendingTerminateLeaseFail,
        agreementInfo: state.dashboard.agreementInfo,
        loggedInUser: state.user.userInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => {
            dispatch(closeTerminateLeaseModal());
        },
        terminateLease: (id, data) => {
            dispatch(terminateLease(id, data));
        },
        updateTerminateNoticeSenderEmailAddresses: emails => {
            dispatch(updateTerminateNoticeSenderEmailAddresses(emails));
        },
        closeTerminateLeaseModal: value => {
            dispatch(closeTerminateLeaseModal(value));
        },
        updateTerminateLeaseData: data => {
            dispatch(updateTerminateLeaseData(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminateLeaseModal);
