import React from 'react';
import { CLIENT_OTHER, CLIENT_TRADING_NAME, CLIENT_TYPE_TO, clientDefaultFields } from '../../../../config';
import { getClientTypeFromDoc } from '../../../../utils/documentUtils.js';
import SummaryItem from './SummaryItem';
import { startCase } from 'lodash';
import { isBusinessUserAccount } from '../../../../utils/userUtils.js';
import { useAccountProvider } from '../../../../components/providers/AccountProvider.js';
import Button from '@app/common/components/Button.js';
import { useCopySigningLink } from '@app/hooks/useSigningLink';
import { ApiModel, ClientSection } from '../buildADoc/BuildADoc.model';
import { Spinner, SpinnerSizes } from '@app/common/design-system/spinner';
import { COPY_DOCUMENT_SIGNING_LINKS } from '@app/constants/featureFlags';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import styles from './ClientSummary.module.scss';

type CopySigningLinkButtonProps = {
    clientId?: string;
    documentId?: string;
    sentForSigningDate?: string;
    hasSigned?: boolean;
};

const CopySigningLinkButton: React.FC<CopySigningLinkButtonProps> = ({
    clientId,
    documentId,
    sentForSigningDate,
    hasSigned
}) => {
    const { copySigningLink, isSigningLinkLoading } = useCopySigningLink(clientId, documentId, sentForSigningDate);

    return (
        <Button
            disabled={isSigningLinkLoading || hasSigned}
            className={styles.copySigningLink}
            link
            onClick={copySigningLink}
            tooltip={hasSigned ? 'Recipient has already signed.' : undefined}
        >
            {isSigningLinkLoading && <Spinner size={SpinnerSizes.SMALL} />}Copy signing link
        </Button>
    );
};

interface ClientSummaryProps {
    currentDocument: ApiModel;
    type: ClientSection;
    showCopySigningLink: boolean;
}

const ClientSummary: React.FC<ClientSummaryProps> = ({ currentDocument, type, showCopySigningLink }) => {
    const isCopySigningLinksEnabled = useFeatureFlag(COPY_DOCUMENT_SIGNING_LINKS);

    const accountType = useAccountProvider();
    let clientType = getClientTypeFromDoc(currentDocument, type);
    if ([CLIENT_OTHER, CLIENT_TRADING_NAME].includes(clientType)) {
        clientType = type.toUpperCase();
    }

    const clients = type === CLIENT_TYPE_TO ? currentDocument.to.clients : currentDocument.from.clients;

    const clientTitle = isBusinessUserAccount(accountType) ? clientType : `${startCase(type)}: ${clientType}`;

    return (
        <div className={styles.container}>
            <h2>{clientTitle}</h2>
            <div className={styles.summaryItems}>
                {clients.map((client, index) => {
                    const items = [
                        { value: client.fullName, isBold: true },
                        { value: client.email },
                        { value: client.mobile }
                    ];

                    const additionalSummaryItems = client.data
                        .filter(field => field.showField && !clientDefaultFields.includes(field.label) && field.value)
                        .map(data => ({
                            value: data.other ? `${data.label}: ${data.value}` : data.value
                        }));

                    return (
                        <div className={styles.client} key={index}>
                            <SummaryItem key={index} items={[...items, ...additionalSummaryItems]} />
                            {showCopySigningLink && isCopySigningLinksEnabled && (
                                <CopySigningLinkButton
                                    clientId={client.id}
                                    documentId={currentDocument.id}
                                    sentForSigningDate={currentDocument.sentForSigning}
                                    hasSigned={!!client.signature}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ClientSummary;
