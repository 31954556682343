import React from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

// Keep track of feature flag subscriptions so we can only add one listener per feature flag
const featureFlagSubscriptions: Record<string, boolean> = {};

/**
 * Reads and writes feature flags to local storage, so we can use the last known feature flag value as its default value.
 * This minimises UI flashes when loading the feature flag values.
 */
// FUTURE IMPROVEMENT: We could store the cached feature flag values in memory to avoid reading from local storage on every render.
export const useCachedFeatureFlag = (featureFlagId: string) => {
    const rawCachedValue = sessionStorage.getItem(featureFlagId);
    const cachedValue = rawCachedValue ? JSON.parse(rawCachedValue) : undefined;
    const featureFlag = useFeatureFlag(featureFlagId, cachedValue);

    React.useEffect(() => {
        const subscribe = () => {
            sessionStorage.setItem(featureFlagId, JSON.stringify(featureFlag ?? null));
        };
        if (!featureFlagSubscriptions[featureFlagId]) {
            window.addEventListener('unload', subscribe);
            featureFlagSubscriptions[featureFlagId] = true;
        }

        return () => {
            window.removeEventListener('unload', subscribe);
            featureFlagSubscriptions[featureFlagId] = false;
        };
    }, [featureFlag, featureFlagId]);

    return featureFlag;
};
