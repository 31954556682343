import { createSelector } from 'reselect';
export const getCommon = state => state.lease.common;
export const getLease = state => state.lease;
export const getRenewal = state => state.renewal;
export const getIsEditingTemplateFinish = createSelector([getCommon], common => common.isEditingTemplateFinish);
export const getLeaseData = createSelector([getLease], lease => lease.leaseData);
//Common
export const getLeaseInfo = createSelector([getCommon], common => common.leaseAllInfo);
export const getStep = createSelector([getCommon], common => common.step);
export const getLeaseId = createSelector([getCommon], common => common.id);
export const getLeaseType = createSelector([getCommon], common => common.leaseType);
export const getSubLeaseType = createSelector([getCommon], common => common.subLeaseType);
export const getDocumentType = createSelector([getCommon], common => common.documentType);
export const getLocation = createSelector([getCommon], common => common.location);
export const getAddress = createSelector([getCommon], common => common.address);
export const getAgent = createSelector([getCommon], common => common.agent);
export const getLeaseInfoAgent = createSelector([getCommon], common => common.leaseAllInfo.agent);
export const getLeaseAllInfo = createSelector([getCommon], common => common.leaseAllInfo);
export const getValidationErrors = createSelector([getCommon], common => common.validationErrors);
export const getIsOpenLeaseAgreementForm = createSelector([getCommon], common => common.isOpenLeaseAgreementForm);
export const getIsEditLeaseAgreementForm = createSelector([getCommon], common => common.isEditLeaseAgreementForm);
export const getIsOpenRequestsToChangeForm = createSelector([getCommon], common => common.isOpenRequestsToChangeForm);
export const getRequests = createSelector([getCommon], common => common.requests);
export const getActiveLeaseRequest = createSelector([getCommon], common => common.activeRequest);
export const getIsSubmitButtonDisabled = createSelector([getCommon], common => common.isSubmitButtonDisabled);
export const getIsLeaseEditingFinished = createSelector([getCommon], common => common.isLeaseEditingFinished);
export const getIsSubmittingLease = createSelector([getCommon], common => common.isSubmittingLease);
export const getDirtyStep = createSelector([getCommon], common => common.dirtyStep);
export const getIsOpenAgreementTemplateForm = createSelector([getCommon], common => common.isOpenAgreementTemplateForm);
export const getIsOpenCloneResTen = createSelector([getCommon], common => common.isOpenCloneResTen);
export const getIsSavingStep = createSelector([getCommon], common => {
    return common.savingStep;
});

export const getListLink = createSelector([getCommon], common => common.listLink);

//Lease
export const getIsRentIncreasePdfEmailSentSuccess = createSelector(
    [getCommon],
    common => common.rentIncreasePdfEmailSentSuccess
);

export const getIsRentIncreasePdfEmailSentFail = createSelector(
    [getCommon],
    common => common.rentIncreasePdfEmailSentFail
);
export const getIsSendingCompletedOwnerCopyEmailSentSuccess = createSelector(
    [getCommon],
    common => common.sendingCompletedOwnerCopyEmailSentSuccess
);

export const getIsSendingCompletedOwnerCopyEmailSentFail = createSelector(
    [getCommon],
    common => common.sendingCompletedOwnerCopyEmailSentFail
);

export const getIsSendingCompletedOwnerCopyEmail = createSelector(
    [getCommon],
    common => common.isSendingCompletedOwnerCopyEmail
);

export const getIsSendingOwnerCopyEmail = createSelector([getCommon], common => common.isSendingOwnerCopyEmail);
export const getLeasePdfEmailSentSuccess = createSelector([getCommon], common => common.leasePdfEmailSentSuccess);
export const getLeasePdfEmailSentFail = createSelector([getCommon], common => common.leasePdfEmailSentFail);
export const getSendingOwnerCopyEmailSentSuccess = createSelector(
    [getCommon],
    common => common.sendingOwnerCopyEmailSentSuccess
);
export const getSendingOwnerCopyEmailSentFail = createSelector(
    [getCommon],
    common => common.sendingOwnerCopyEmailSentFail
);
export const getIsSendingLeaseManualSigningEmail = createSelector(
    [getCommon],
    common => common.isSendingLeaseManualSigningEmail
);
export const getLeaseManualSigningEmailSentSuccess = createSelector(
    [getCommon],
    common => common.leaseManualSigningEmailSentSuccess
);
export const getLeaseManualSigningEmailSentFail = createSelector(
    [getCommon],
    common => common.leaseManualSigningEmailSentFail
);

export const getCreateLeaseError = createSelector([getCommon], common => common.createLeaseError);

export const getIsSendingLeasePdfEmail = createSelector([getCommon], common => common.isSendingLeasePdfEmail);
export const getLandlord = createSelector([getLease], lease => {
    return lease.landlord;
});

// Commercial Lease fields
export const getLessor = createSelector([getLeaseData], data => {
    return data.lessor;
});
export const getLessee = createSelector([getLeaseData], data => {
    return data.lessee;
});
export const getGuarantor = createSelector([getLeaseData], data => {
    return data.guarantor;
});
export const getPremises = createSelector([getLeaseData], data => {
    return data.premises;
});
export const getPermittedUse = createSelector([getLeaseData], data => {
    return data.permittedUse;
});
export const getSecurityDeposit = createSelector([getLeaseData], data => {
    return data.securityDeposit;
});
export const getInformation = createSelector([getLeaseData], data => {
    return data.information;
});

export const getRent = createSelector([getLease], lease => lease.rent);
export const getSignatory = createSelector([getLease], lease => lease.signatory);
export const getLandlordsSolicitor = createSelector([getLease], lease => lease.landlordsSolicitor);
export const getTenant = createSelector([getLease], lease => lease.tenant);
export const getTenants = createSelector([getLease], lease => {
    return lease.tenant ? lease.tenant.tenants : [];
});
export const getTenantsAgent = createSelector([getLease], lease => lease.tenantsAgent);
export const getTerm = createSelector([getLease], lease => lease.term);
export const getSalesTerm = createSelector([getLease], lease => lease.salesTerm);
export const getPaymentMethod = createSelector([getLeaseInfo], lease => lease.paymentMethod);
export const getOccupants = createSelector([getLease], lease => lease.maxOccupants);
export const getInclusions = createSelector([getLease], lease => lease.inclusions);
export const getExclusions = createSelector([getLease], lease => lease.exclusions);
export const getOutgoings = createSelector([getLease], lease => lease.outgoings);
export const getInsurance = createSelector([getLease], lease => lease.insurance);
export const getDisclosure = createSelector([getLease], lease => lease.disclosure);
export const getPets = createSelector([getLease], lease => lease.pets);
export const getInstructions = createSelector([getLease], lease => lease.instructions);
export const getExecutedAsAgreement = createSelector([getLease], lease => lease.executedAsAgreement);
export const getIntegration = createSelector([getLeaseInfo], info => info.integration);
export const getLeaseEmergencyContacts = createSelector([getLease], lease => lease.emergencyContacts);
export const getRepairsAndMaintenance = createSelector([getLease], lease => lease.repairsAndMaintenance);
export const getNominatedRepairers = createSelector([getLease], lease => lease.nominatedRepairers);
export const getLeaseSpecialConditions = createSelector([getLeaseInfo], lease => lease.specialConditions);
export const getLeaseDocuments = createSelector([getLease], lease => lease.documents.documents);
export const getMaterialFacts = createSelector([getLease], lease => lease.materialFacts);
export const getAdditionalTerms = createSelector([getLease], info => info.additionalTerms);
export const getConnections = createSelector([getLease], lease => lease.connections);
export const getLeaseTenantLinks = createSelector([getCommon], common => {
    return common.leaseAllInfo.tenantLinks ? common.leaseAllInfo.tenantLinks : { links: [] };
});
export const getDocumentUploadProgress = createSelector([getCommon], common => common.documentUploadProgress);
export const getCreateNewModalOpenedFromWelcome = createSelector(
    [getCommon],
    common => common.createNewModalOpenedFromWelcome
);
export const getSuggestions = createSelector([getCommon], common => common.suggestions);

export const getIntegrationLeaseDataSync = createSelector([getCommon], common => common.integrationDataSync);

export const getCreateTemplateError = createSelector([getCommon], common => common.createTemplateError);

export const getCloneTemplate = createSelector([getCommon], common => common.cloneTemplate);