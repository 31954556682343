import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openEditVendorsModal, openEditLandlordsModal } from '../../actions/dashboard.js';

export function useLegacyDashboardActions() {
    const history = useHistory();
    const dispatch = useDispatch();

    async function clone(agreementId: string) {
        const { data } = await axios.post(`/api/agency/lease/${agreementId}/clone`);

        history.push(`/dashboard/agreement/${data.lease.id}`);
    }

    async function editVendor(agreementId: string) {
        const { data } = await axios.get(`/api/agency/lease/${agreementId}`);
        dispatch(openEditVendorsModal(data.lease));
    }

    async function editLandlord(agreementId: string) {
        const { data } = await axios.get(`/api/agency/lease/${agreementId}`);
        dispatch(openEditLandlordsModal(data.lease));
    }

    async function performLegacyAction(action: string, agreementId: string) {
        switch (action) {
            case 'open':
                history.push(`/dashboard/agreement/${agreementId}`);
                break;
            case 'clone':
                await clone(agreementId);
                break;
            case 'delete':
                await axios.delete(`/api/agency/lease/${agreementId}`);
                break;
            case 'move-to-drafts':
                await axios.post(`/api/agency/lease/${agreementId}/cancel-signing`);
                break;
            case 'edit-vendor':
                await editVendor(agreementId);
                break;
            case 'edit-landlord':
                await editLandlord(agreementId);
                break;
        }
    }

    return { performLegacyAction };
}
