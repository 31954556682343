import React, { memo } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_CUSTOM,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    BREACH_NOTICE,
    ENTRY_NOTICE,
    SUB_LEASE_TYPE_SA_RENEWALS,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY,
    NSW_STATE
} from '../../config';

import '../../sass/modalCreateNewAgreement.scss';
import { PictureAsPdfSharp } from '@material-ui/icons';
import TrendingUpIcon from '@material-ui/icons/TrendingUpSharp';
import TrendingDownIcon from '@material-ui/icons/TrendingDownSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import AnnouncementSharpIcon from '@material-ui/icons/AnnouncementSharp';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import CommercialLeaseIcon from '@material-ui/icons/BusinessSharp';
import { useAccountProvider } from '../providers/AccountProvider';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../utils/agreementUtils';
import { getAgencyDefaultLocation } from '../../selectors/user';
import useOpenCreationModal from '../../hooks/useOpenCreationModal';
import cx from 'classnames';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import {
    NSW_HOLIDAY_LETTING,
    NSW_ENTRY_NOTICE,
    QLD_ENTRY_NOTICE,
    QLD_BREACH_NOTICE,
    NSW_INTENTION_TO_SELL,
    NSW_ESTIMATED_SELLING_PRICE_NOTICE,
    SALES_V2,
    PROPERTY_MANAGEMENT_V2
} from '../../constants/featureFlags';
import Icon, { Icons } from '../../common/components/Icon';
import { openSubapp } from '../../utils/openSubapp';
import { HOLIDAY_LETTING_SUBAPP_PATH, PM_SUBAPP_PATH, SALES_SUBAPP_PATH } from '../../constants/constants';
import { useCachedFeatureFlag } from '@app/hooks/useCachedFeatureFlag';

import styles from './ModalCreateNewAgreement.module.scss';

const ModalCreateNewAgreement = ({ isOpen, close }) => {
    const accountType = useAccountProvider();
    const isNswHolidayLettingActive = useCachedFeatureFlag(NSW_HOLIDAY_LETTING);
    const isNswEntryNoticeActive = useFeatureFlag(NSW_ENTRY_NOTICE);
    const isQldEntryNoticeActive = useFeatureFlag(QLD_ENTRY_NOTICE);
    const isQldBreachNoticeActive = useFeatureFlag(QLD_BREACH_NOTICE);
    const isNswIntentionToSellActive = useFeatureFlag(NSW_INTENTION_TO_SELL);
    const isSalesV2Active = useCachedFeatureFlag(SALES_V2);
    const isPmV2Active = useCachedFeatureFlag(PROPERTY_MANAGEMENT_V2);
    const isNswEstimatedSellingPriceNoticeActive = useFeatureFlag(NSW_ESTIMATED_SELLING_PRICE_NOTICE);
    const location = useSelector(getAgencyDefaultLocation);

    const afterOpenCreationModal = () => close();

    const {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isActiveUser,
        handleOpenSubLeaseAgreementForm
    } = useOpenCreationModal(afterOpenCreationModal);

    return (
        <Modal
            parentSelector={() => document.getElementById('main-layout')}
            ariaHideApp={false}
            isOpen={isOpen}
            style={customStyle}
            className={cx('modal-create-new-agreement', {
                [`${styles.modalCreateNewAgreementWidth375}`]: isNswEstimatedSellingPriceNoticeActive
            })}
            contentLabel="Modal"
            onRequestClose={close}
            bodyOpenClassName="scroll-locked"
        >
            <div className="create-new-agreement">
                {isAgencyUserAccount(accountType) && (
                    <React.Fragment>
                        <h2>Create new agreement</h2>
                        <ul>
                            {isLeaseTypeEnabled(LEASE_TYPE_RESIDENTIAL) && (
                                <li
                                    onClick={() => openLeaseAgreementCreationModal(LEASE_TYPE_RESIDENTIAL)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <i className="icon icon-tenancy" />
                                    Residential Tenancy
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_SALES) && (
                                <>
                                    {isSalesV2Active ? (
                                        <li
                                            onClick={() =>
                                                location === NSW_STATE
                                                    ? openSubapp(SALES_SUBAPP_PATH)
                                                    : openLeaseAgreementCreationModal(LEASE_TYPE_SALES)
                                            }
                                            className={isActiveUser() ? '' : 'disabled'}
                                        >
                                            <i className="icon icon-sales" />
                                            Sales
                                        </li>
                                    ) : (
                                        <li
                                            onClick={() => openLeaseAgreementCreationModal(LEASE_TYPE_SALES)}
                                            className={isActiveUser() ? '' : 'disabled'}
                                        >
                                            <i className="icon icon-sales" />
                                            Sales
                                        </li>
                                    )}
                                </>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_PROPERTY_MANAGEMENT) && (
                                <>
                                    {isPmV2Active ? (
                                        <li
                                            onClick={() =>
                                                location === NSW_STATE
                                                    ? openSubapp(PM_SUBAPP_PATH)
                                                    : openLeaseAgreementCreationModal(LEASE_TYPE_PROPERTY_MANAGEMENT)
                                            }
                                            className={isActiveUser() ? '' : 'disabled'}
                                        >
                                            <i className="icon icon-property" />
                                            Property management
                                        </li>
                                    ) : (
                                        <li
                                            onClick={() =>
                                                openLeaseAgreementCreationModal(LEASE_TYPE_PROPERTY_MANAGEMENT)
                                            }
                                            className={isActiveUser() ? '' : 'disabled'}
                                        >
                                            <i className="icon icon-property" />
                                            Property management
                                        </li>
                                    )}
                                </>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_LEASE) && (
                                <li
                                    onClick={() => openLeaseAgreementCreationModal(LEASE_TYPE_COMMERCIAL_LEASE)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <CommercialLeaseIcon className="icon" />
                                    Commercial lease
                                </li>
                            )}
                            {isNswHolidayLettingActive && isLeaseTypeEnabled(LEASE_TYPE_HOLIDAY_LETTING) && (
                                <li
                                    onClick={() => openSubapp(HOLIDAY_LETTING_SUBAPP_PATH)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <Icon icon={Icons.HOLIDAY_HOUSE} className="icon" />
                                    Holiday letting
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                )}
                <h2>Create new document</h2>
                {isAgencyUserAccount(accountType) && (
                    <ul>
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_RENT_REDUCTION)}
                            className={isActiveUser() ? '' : 'disabled'}
                        >
                            <TrendingDownIcon className="icon" />
                            Rent relief agreement
                        </li>
                        {isLeaseTypeEnabled(DOCUMENT_TERMINATION_NOTICE) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_TERMINATION_NOTICE)}
                                className={isActiveUser() ? '' : 'disabled'}
                            >
                                <i className="icon icon-termination-notice" />
                                Termination notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(DOCUMENT_RENT_INCREASE) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_RENT_INCREASE)}
                                className={isActiveUser() ? '' : 'disabled'}
                            >
                                <TrendingUpIcon className="icon" />
                                Rent increase notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(BREACH_NOTICE) &&
                            (isQldBreachNoticeActive || !isLeaseLocationQLD(location)) && (
                                <li
                                    onClick={() => openDocumentCreationModal(BREACH_NOTICE)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <AnnouncementSharpIcon className="icon" />
                                    Breach Notice
                                </li>
                            )}
                        {/* 
                            TODO: Remove this when the feature flags are cleaned up
                            If the default state of the agency is QLD and the feature flag QLDEntryNotice is disabled: 
                            - The "QLD Entry Notice" should not appear in the blue side menu. 
                        */}
                        {((isNswEntryNoticeActive && isLeaseLocationNSW(location)) ||
                            (isQldEntryNoticeActive && isLeaseLocationQLD(location))) &&
                            isLeaseTypeEnabled(ENTRY_NOTICE) && (
                                <li
                                    onClick={() => openDocumentCreationModal(ENTRY_NOTICE)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <Icon icon={Icons.ENTRY_DOOR} className="icon" />
                                    Entry Notice
                                </li>
                            )}
                        {isLeaseTypeEnabled(DOCUMENT_INTENTION_TO_SELL) &&
                            isNswIntentionToSellActive &&
                            isLeaseLocationNSW(location) && (
                                <li
                                    onClick={() => openDocumentCreationModal(DOCUMENT_INTENTION_TO_SELL)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <Icon icon={Icons.MEGAPHONE} className="icon" />
                                    Intention to sell notice
                                </li>
                            )}
                        {isLeaseTypeEnabled(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE) &&
                            isNswEstimatedSellingPriceNoticeActive &&
                            isLeaseLocationNSW(location) && (
                                <li
                                    onClick={() => openDocumentCreationModal(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE)}
                                    className={isActiveUser() ? '' : 'disabled'}
                                >
                                    <Icon icon={Icons.ESP} className="icon" />
                                    Estimated selling price notice
                                </li>
                            )}
                        {isLeaseTypeEnabled(DISCLOSURE_DOCUMENT) && (
                            <li
                                onClick={() => openDocumentCreationModal(DISCLOSURE_DOCUMENT)}
                                className={isActiveUser() ? '' : 'disabled'}
                            >
                                <i className="icon-disclosure_document" />
                                Disclosure document - VIC
                            </li>
                        )}
                        {isLeaseTypeEnabled(SUB_LEASE_TYPE_SA_RENEWALS) && (
                            <li
                                onClick={() => handleOpenSubLeaseAgreementForm(SUB_LEASE_TYPE_SA_RENEWALS)}
                                className={isActiveUser() ? '' : 'disabled'}
                            >
                                <RefreshSharpIcon className="icon" />
                                Lease extension - SA
                            </li>
                        )}
                    </ul>
                )}
                <ul>
                    {isLeaseTypeEnabled(DOCUMENT_CUSTOM) && (
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_CUSTOM)}
                            className={isActiveUser() ? '' : 'disabled'}
                        >
                            <PictureAsPdfSharp className="icon" />
                            {FLK_A_PDF_DISPLAY}
                        </li>
                    )}
                    {isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) && (
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_CREATE_A_FLK)}
                            className={isActiveUser() ? '' : 'disabled'}
                        >
                            <DescriptionSharpIcon className="icon" />
                            {CREATE_A_FLK_DISPLAY}
                        </li>
                    )}
                </ul>
                {isAgencyUserAccount(accountType) && (
                    <React.Fragment>
                        <h2>FLK a Key (Free trial)</h2>
                        <ul>
                            <li className="" onClick={() => openFlkAKeyCreationModal()}>
                                <div className="icon">
                                    <span className="key-icon key-icon-blue"></span>
                                </div>
                                Check out a Key
                            </li>
                        </ul>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
};

export default memo(ModalCreateNewAgreement);

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.67)',
        zIndex: 5
    }
};
