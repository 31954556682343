import axios from 'axios';
import * as Login from './../reducers/login';
import { HARNESS_TARGET_DATA } from '@app/constants/constants';

/**
 * Logout user.
 * @return {{type: string}}
 */
export function logOut() {
    window.sessionStorage.removeItem(HARNESS_TARGET_DATA);
    return {
        types: [Login.LOGOUT_REQUEST, Login.LOGOUT_SUCCESS, Login.LOGOUT_FAILURE],
        promise: axios.post(`/api/user/log-out`)
    };
}

export function logOutSuccess() {
    window.sessionStorage.removeItem(HARNESS_TARGET_DATA);
    return {
        type: Login.LOGOUT_SUCCESS
    };
}

/**
 * Login request.
 * @param {Object} data - user credentials
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function logIn(data, hasUpdate) {
    window.sessionStorage.removeItem(HARNESS_TARGET_DATA);
    return dispatch => {
        dispatch({
            type: Login.LOG_IN_REQUEST,
            rememberMe: data.rememberMe,
            hasUpdate
        });

        return new Promise((resolve, reject) => {
            return axios
                .post('/auth/login', data, { withCredentials: true })
                .then(result => {
                    dispatch({
                        type: Login.LOG_IN_SUCCESS,
                        result,
                        rememberMe: data.rememberMe
                    });
                    resolve(result);
                })
                .catch(error => {
                    dispatch({
                        type: Login.LOG_IN_FAILURE,
                        error,
                        rememberMe: data.rememberMe
                    });
                    reject(error);
                });
        });
    };
}

export function setLoginRedirect(path, accountType = '') {
    return {
        type: Login.SET_LOGIN_REDIRECT,
        payload: {
            redirect: path,
            accountType
        }
    };
}

export function clearLoginUser() {
    return {
        type: Login.CLEAR_LOGIN_USER
    };
}

export function saveDirectLeaseId(leaseId) {
    return {
        type: Login.SAVE_DIRECT_LEASE_ID,
        payload: { directLeaseId: leaseId }
    };
}

export function clearDirectLeaseId() {
    return {
        type: Login.CLEAR_DIRECT_LEASE_ID
    };
}
